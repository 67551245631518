import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicComponentDirective } from '../../../directives/dynamic-component.directive';
import * as Models from '../../../models/models-index';
import { ElementComponent } from '../../../models/models-index';
import { PanelElementFormattingService } from '../panel-element-formatting.service';
import { PanelElementResolverService } from '../panel-element-resolver.service';

@Component({
  selector: 'row-panel',
  templateUrl: './row-panel.component.html',
  styleUrls: ['./row-panel.component.scss']
})
export class RowPanelComponent implements Models.IRowPanel, OnInit, AfterViewInit {

  @ViewChild('innerRowPanelContainer') innerRowPanelContainer: ElementRef;
  @ViewChild(DynamicComponentDirective, { static: true }) dynamicComponent!: DynamicComponentDirective;
  @Input() panels: Models.Panel[];
  @Input() rowIndex: number;
  @Input() response: Models.ReportViewResponse;
  @Input() rowPanelId: string;
  @Input() translationService: Models.ITranslationService;
  @Input() viewContext?: Models.ViewContext;

  panelTitle;
  elementId;

  constructor(private resolverService: PanelElementResolverService,
    private renderer2: Renderer2) { }

  ngOnInit(): void {
    this.elementId = `panel-row-${this.rowIndex}`;
    this.generatePanels();
  }

  ngAfterViewInit(): void {
    this.renderer2.setAttribute(this.innerRowPanelContainer.nativeElement, 'id', this.elementId);
  }

  generatePanels() {

    const viewContainerRef = this.dynamicComponent.viewContainerRef;
    viewContainerRef.clear();

    this.panels.forEach((panel, index) => {
      const componentFactory = this.resolverService.resolvePanelComponent(panel.type);
      const componentRef = viewContainerRef.createComponent<Models.PanelComponent>(componentFactory);
      componentRef.instance.panelConfig = panel;
      componentRef.instance.dataSets = this.response.dataSets;
      componentRef.instance.rowPanelId = this.elementId;
      componentRef.instance.translationService = this.translationService;
      componentRef.instance.viewContext = this.viewContext;
      if (index !== this.panels.length - 1) {
        this.renderer2.setStyle(componentRef.location.nativeElement, 'margin-right', '15px');
      }

      this.renderer2.setStyle(componentRef.location.nativeElement, 'width', '100%');
      this.renderer2.setStyle(componentRef.location.nativeElement, 'min-width', '0px');
    })
  }
}
